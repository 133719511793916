import Link from "next/link";
import NavigationStatus from "../config/navigation";
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF'
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn'

export default function Footer() {
    return (
        <footer>
            <div className='footer-grid'>
                <div className='footer-branding text-white'>
                    {
                        NavigationStatus?.FOOTER_LOGO &&
                        <div className='logo mb-2'>
                            <img src={process.env.NEXT_PUBLIC_BRAND_LOGO} alt='logo' width="120px" height="42px" />
                        </div>
                    }
                </div>
                <div className='footer-links-1 footer-nav-grid text-white'>
                    {
                        NavigationStatus?.BLOG &&
                        <Link href='/blog'>
                            <a>
                                <h5 className='footer-link'>Blog</h5>
                            </a>
                        </Link>
                    }
                    {
                        NavigationStatus?.CAREER &&
                        <Link href='/careers'>
                            <a>
                                <h5 className='footer-link'>Careers</h5>
                            </a>
                        </Link>
                    }
                    {
                        NavigationStatus?.CONTACT_US &&
                        <Link href='/contact'>
                            <a>
                                <h5 className='footer-link'>Contact Us</h5>
                            </a>
                        </Link>
                    }
                    {
                        NavigationStatus?.TESTIMONIAL &&
                        <Link href='/testimonials'>
                            <a>
                                <h5 className='footer-link'>Testimonials</h5>
                            </a>
                        </Link>
                    }
                    {
                        NavigationStatus?.SAT_VS_ACT &&
                        <Link href='/sat-vs-act'>
                            <a>
                                <h5 className='footer-link'>SAT vs ACT</h5>
                            </a>
                        </Link>
                    }
                    {
                        NavigationStatus?.ADMISSION &&
                        <a href={process.env.NEXT_PUBLIC_ADMISSION_URI} target='_blank' rel='noreferrer'>
                            <h5 className='footer-link'>Admissions</h5>
                        </a>
                    }
                    {
                        NavigationStatus?.PARTNER_SIGN_UP &&
                        <a href={process.env.NEXT_PUBLIC_PARTNER_URI + "signup?as=agent"} target='_blank' rel='noreferrer'>
                            <h5 className='footer-link'>Partner Signup</h5>
                        </a>
                    }
                    {
                        NavigationStatus?.EDUCATOR_SIGN_UP &&
                        <Link href='/educators'>
                            <a>
                                <h5 className='footer-link'>Educators</h5>
                            </a>
                        </Link>
                    }
                    {
                        NavigationStatus?.PARENT &&
                        <Link href='/parents'>
                            <a>
                                <h5 className='footer-link'>Parents</h5>
                            </a>
                        </Link>
                    }
                    {
                        NavigationStatus?.PRICING &&
                        <Link href='/pricing'>
                            <a>
                                <h5 className='footer-link'>Buy Now</h5>
                            </a>
                        </Link>
                    }
                    {
                        NavigationStatus?.REFUND_POLICY &&
                        <Link href='/refund'>
                            <a>
                                <h5 className='footer-link'>Refund Policy</h5>
                            </a>
                        </Link>
                    }
                    {
                        NavigationStatus?.PRIVACY_POLICY &&
                        <Link href='/privacy'>
                            <a>
                                <h5 className='footer-link'>Privacy Policy</h5>
                            </a>
                        </Link>
                    }
                    {
                        NavigationStatus?.TERMS_AND_CONDITION &&
                        <Link href='/terms'>
                            <a>
                                <h5 className='footer-link'>Terms &amp; Conditions</h5>
                            </a>
                        </Link>
                    }
                </div>
                {
                    NavigationStatus?.SOCIAL_NETWORKS &&
                    <div className='footer-links-social'>
                        <h5 className='mb-3'>Follow Us</h5>
                        <div className='d-flex justify-content-center justify-content-xl-start text-white'>
                            {
                                NavigationStatus?.FACEBOOK &&
                                <a href={NavigationStatus.FACEBOOK} target='_blank' rel='noreferrer'>
                                    <div className='footer-social-circle me-3'>
                                        <FaFacebookF />
                                    </div>
                                </a>
                            }
                            {
                                NavigationStatus?.TWITTER &&
                                <a href={NavigationStatus.TWITTER} target='_blank' rel='noreferrer'>
                                    <div className='footer-social-circle me-3'>
                                        <FaTwitter />
                                    </div>
                                </a>
                            }
                            {
                                NavigationStatus?.LINKED_IN &&
                                <a href={NavigationStatus.LINKED_IN} target='_blank'
                                    rel='noreferrer'>
                                    <div className='footer-social-circle'>
                                        <FaLinkedinIn />
                                    </div>
                                </a>
                            }
                        </div>
                    </div>
                }
            </div>

            <hr className='my-3' />

            <div className='footer-copyright text-center'>
                <h5 className='mb-3'>&copy; Copyright {new Date().getFullYear()} {process.env.NEXT_PUBLIC_COMPANY_NAME}</h5>
            </div>
        </footer>
    );
}
