import React from "react";
import Link  from "next/link";
import NavigationStatus from "../config/navigation";
import PropTypes from "prop-types";
import { FcReading } from "@react-icons/all-files/fc/FcReading";
import { MdSchool } from "@react-icons/all-files/md/MdSchool";
import { MdArrowForward } from "@react-icons/all-files/md/MdArrowForward";
import { MdMenu } from "@react-icons/all-files/md/MdMenu";
import { withRouter } from 'next/router';

class Navigation extends React.Component {
    state = {
        route: "",
        query: ""
    };

    componentDidMount() {
        this.mobileNavToggle();

        // Set route variable
        setTimeout(() => {
            let query = sessionStorage.getItem("referral") ? '?referralCode=' + sessionStorage.getItem("referral") : "";
            this.setState({
                route: this.props.router.route,
                query: query
            });
        }, 300)
    }

    mobileNavToggle = () => {
        document.querySelector(".mobile-header--icon").addEventListener("click", () => {
            document.querySelector(".mobile-nav-links").classList.toggle("show");
        });
        // const anchorList = document.querySelectorAll(".mobile-nav-links ul li a");
        // anchorList?.length > 0 && anchorList.forEach((el, key) => {
        //     console.log(el)
        //     el.addEventListener('click', this.closeTopSheet);
        // })
    };
    // closeTopSheet = (e) => {
    //     console.log('fire')
    //     document.querySelector(".mobile-nav-links").classList.remove("show");
    // }

    render() {
        const { query } = this.state;
        const IndiaLinks = () => {
            return (
                <ul className='navigation-links'>
                    {
                        NavigationStatus?.DigitalSAT &&
                        <li className={this.state.route === "/DigitalSAT"? "active-link" : ""}>
                            <Link href='/course/digital-sat'>
                                <a>Digital SAT</a>
                            </Link>
                        </li>
                    }
                    {
                        NavigationStatus?.SAT &&
                        <li className={this.state.route === "/SAT" ||  this.state.route === "/SATRouter"? "active-link" : ""}>
                            <Link href='/course/sat'>
                                <a>SAT</a>
                            </Link>
                        </li>
                    }
                    {
                        NavigationStatus?.ACT &&
                        <li className={this.state.route === "/ACT" ? "active-link" : ""}>
                            <Link href='/course/act'>
                                <a>ACT</a>
                            </Link>
                        </li>
                    }
                    {
                        NavigationStatus?.SAT_VS_ACT &&
                        <li className={(this.state.route === '/SatVsActLanding' ? 'active-link' : '')}>
                            <Link href="/course/sat-vs-act">
                                <a>SAT vs ACT</a>
                            </Link>
                        </li>
                    }
                    {
                        NavigationStatus?.ESSAY &&
                        <li className={(this.state.route === '/EssayLanding' ? 'active-link' : '')}>
                            <Link href="/essay">
                                <a>AI Enabled Essay</a>
                            </Link>
                        </li>
                    }
                    {
                        NavigationStatus?.MOCK_TEST &&
                        <li className={this.state.route === "/MockTest" ? "active-link" : ""}>
                            <Link href='/mocktest'>
                                <a>Mock Tests</a>
                            </Link>
                        </li>
                    }
                    {/* {
                        NavigationStatus?.LIBERATE &&
                        <li className={this.state.route === "/LiberateLanding" ? "active-link" : ""}>
                            <Link href='/liberate'>
                                <a>Liberate</a>
                            </Link>
                        </li>
                    } */}
                    {
                        NavigationStatus?.LIBERATE &&
                        <li className='nav-button'>
                            <a href={process.env.NEXT_PUBLIC_LIBERATE_URI}>
                                <span className='d-flex align-items-center text-grey'>
                                    <FcReading className='me-2' />
                                    Liberate
                                </span>
                            </a>
                        </li>
                    }
                    {
                        NavigationStatus?.ADMISSION &&
                        <li className='nav-button'>
                            <a href={process.env.NEXT_PUBLIC_ADMISSION_URI}>
                                <span className='d-flex align-items-center text-grey'>
                                    <MdSchool className='me-2 text-grey' />
                                Admissions
                                </span>
                            </a>
                        </li>
                    }
                    {
                        NavigationStatus?.EVENTS &&
                        <li className={this.state.route === "/EventList" ? "active-link" : ""}>
                            <Link href='/events'>
                                <a>Events</a>
                            </Link>
                        </li>
                    }
                    {
                        !NavigationStatus?.MORE && NavigationStatus?.ONLINE_CLASS &&
                        <li className={this.state.route === "/SATCampaign" ? "active-link" : ""}>
                            <Link href='/sat-online-classes'>
                                <a>SAT Online Classes</a>
                            </Link>
                        </li>
                    }
                    {
                        !NavigationStatus?.MORE && NavigationStatus?.BLOG &&
                        <li className={this.state.route === "/blog" ? "active-link" : ""}>
                            <Link href='/blog'>
                                <a>Blog</a>
                            </Link>
                        </li>
                    }
                    {
                        NavigationStatus?.MORE &&
                        <li className='dropdown'>
                            <a
                                id='dropdownMenuButton'
                                className='dropdown-toggle d-inline-flex align-items-center'
                                role="button" data-bs-toggle="dropdown" aria-expanded="false"
                            >
                                <label className='mb-0 pointer'>MORE</label>
                                <span
                                    className='badge bg-primary my-auto ms-2 fw-bolder'
                                    style={{ fontSize: 10, padding: "5px 8px" }}
                                >
                                    NEW
                                </span>
                            </a>
                            <div
                                className='dropdown-menu shadow-lg border-0 mt-2'
                                aria-labelledby='dropdownMenuButton'
                            >
                                {
                                    NavigationStatus?.EDUCATOR &&
                                    <Link href='/educators'>
                                        <a className='dropdown-item p-2 ps-4'>Educators</a>
                                    </Link>
                                }
                                {
                                    NavigationStatus?.ONLINE_CLASS &&
                                    <Link href='/sat-online-classes'>
                                        <a className='dropdown-item p-2 ps-4'>SAT Online Classes</a>
                                    </Link>
                                }
                                {
                                    NavigationStatus?.SAT_CRASH_COURSE &&
                                    <Link href='/sat-crash-course'>
                                        <a className='dropdown-item p-2 ps-4'>Boot Camp</a>
                                    </Link>
                                }
                                {
                                    NavigationStatus?.AI_TOOL &&
                                    <Link href='/ai'>
                                        <a className='dropdown-item p-2 ps-4'>AI Tools</a>
                                    </Link>
                                }
                                {
                                    NavigationStatus?.BLOG &&
                                    <Link href='/blog'>
                                        <a className='dropdown-item p-2 ps-4'>Blog</a>
                                    </Link>
                                }
                            </div>
                        </li>
                    }
                </ul>
            );
        };

        return (
            <div>
                {/* Desktop */}
                <nav className='desktop-only navigation-wrapper'>
                    <div className='container-fuild flex-row justify-content-between align-items-md-center'>
                        <div className='flex-row align-items-md-center'>
                            <div className='logo-wrapper me-2'>
                                <Link href='/'>
                                    <a>
                                        <img src={process.env.NEXT_PUBLIC_BRAND_LOGO} alt='Brand-Logo' width="90px" height="32px" />
                                    </a>
                                </Link>
                            </div>
                            <div className='nav-topics'>
                                 <IndiaLinks />
                            </div>
                        </div>
                        <div className='nav-right-wrapper'>
                            {
                                this.props.isUserExist === undefined ? (
                                    <ul className='navigation-links nav-cta'>
                                        {
                                            NavigationStatus?.PRICING &&
                                            <li className={this.state.route === "/Pricing" ? "active-link" : ""}>
                                                <Link href='/pricing'>
                                                    <a>Pricing</a>
                                                </Link>
                                            </li>
                                        }
                                        {
                                            NavigationStatus?.SIGN_IN &&
                                            <li className='me-2'>
                                                <a href={process.env.NEXT_PUBLIC_FRONT_URI + "login" + query}>
                                                    Sign In
                                                </a>
                                            </li>
                                        }
                                        {
                                            NavigationStatus?.SIGN_IN_MENU &&
                                            <li className="dropdown">
                                                <a className="dropdown-toggle" href="#" id="navDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Sign In
                                                </a>
                                                <div className="dropdown-menu mt-2" aria-labelledby="navDropdown">
                                                    {
                                                        NavigationStatus?.SIGN_IN_FULL_COURSE &&
                                                        <a className="dropdown-item" href={process.env.NEXT_PUBLIC_FRONT_URI + "login" + query}>Sign In - Full Course</a>
                                                    }
                                                    {
                                                        NavigationStatus?.SIGN_IN_MOCK_TEST &&
                                                        <a href={process.env.NEXT_PUBLIC_TESTPREP_URI} className='dropdown-item'>Sign In - Mock Tests</a>
                                                    }
                                                </div>
                                            </li>
                                        }
                                        {
                                            NavigationStatus?.SIGN_UP &&
                                            <li className='nav-button'>
                                                <a href={process.env.NEXT_PUBLIC_FRONT_URI + "signup" + query}>
                                                    <button className='btn btn-solid-small --secondary'>
                                                    {
                                                        process.env.NEXT_PUBLIC_ENABLE_TRIAL === "true"?
                                                        'Start Free Trial'
                                                        :
                                                        'Enroll Now'
                                                    }
                                                        <MdArrowForward fontSize='20px' className='ms-2' />
                                                    </button>
                                                </a>
                                            </li>
                                        }
                                    </ul>
                                ) : this.props.isUserExist === "" ? (
                                    <label className='lds-ellipsis'>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </label>
                                ) : this.props.isUserExist ? (
                                    <ul className='navigation-links'>
                                        {
                                            NavigationStatus?.SIGN_IN &&
                                            <li className='nav-button'>
                                                <a href={process.env.NEXT_PUBLIC_FRONT_URI + "login" + query} className='btn btn-primary'>
                                                    Login
                                                    <MdArrowForward className='ms-2' />
                                                </a>
                                            </li>
                                        }
                                    </ul>
                                ) : (
                                    <ul className='navigation-links'>
                                        {
                                            NavigationStatus?.SIGN_UP &&
                                            <li className='nav-button'>
                                                <a href={process.env.NEXT_PUBLIC_FRONT_URI + "signup" + query} className='btn btn-primary'>
                                                    {
                                                        process.env.NEXT_PUBLIC_ENABLE_TRIAL === "true"?
                                                        'Start Free Trial'
                                                        :
                                                        'Enroll Now'
                                                    }
                                                    <MdArrowForward className='ms-2' />
                                                </a>
                                            </li>
                                        }
                                    </ul>
                                )}
                        </div>
                    </div>
                </nav>

                {/* Mobile */}
                <nav className='mobile-only'>
                    <div className='navigation-wrapper'>
                        <div className='d-flex justify-content-between align-items-center h-100'>
                            <div className='logo-wrapper ms-2'>
                                <Link href='/'>
                                    <a>
                                        <picture>
                                            <img src={process.env.NEXT_PUBLIC_BRAND_LOGO} alt='Brand-Logo' width="90px" height="32px" />
                                        </picture>
                                    </a>
                                </Link>
                            </div>
                            <div className='flex-row align-items-center'>
                                {this.props.isUserExist === undefined ? (
                                    <div className='mobile-nav-cta'>
                                        {
                                            NavigationStatus?.SIGN_UP &&
                                            <a href={process.env.NEXT_PUBLIC_FRONT_URI + "signup" + query} className='btn btn-primary'>
                                                {
                                                    process.env.NEXT_PUBLIC_ENABLE_TRIAL === "true"?
                                                    'Free Trial'
                                                    :
                                                    'Enroll Now'
                                                }
                                            </a>
                                        }
                                    </div>
                                ) : this.props.isUserExist === "" ? (
                                    <label className='lds-ellipsis'>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </label>
                                ) : this.props.isUserExist ? (
                                    <div className='mobile-nav-cta'>
                                        {
                                            NavigationStatus?.SIGN_IN &&
                                            <a href={process.env.NEXT_PUBLIC_FRONT_URI + "login" + query} className='btn btn-primary'>
                                                Login
                                            </a>
                                        }
                                    </div>
                                ) : (
                                    <div className='mobile-nav-cta'>
                                        {
                                            NavigationStatus?.SIGN_UP &&
                                            <a href={process.env.NEXT_PUBLIC_FRONT_URI + "signup" + query} className='btn btn-primary'>
                                                {
                                                    process.env.NEXT_PUBLIC_ENABLE_TRIAL === "true"?
                                                    'Free Trial'
                                                    :
                                                    'Enroll Now'
                                                }
                                            </a>
                                        }
                                    </div>
                                )}
                                <button className='mobile-header--icon ms-2' type='button' aria-label='menu'>
                                    <MdMenu fontSize='24px' />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='mobile-nav-links'>
                        <IndiaLinks />
                        {
                            NavigationStatus?.PRICING &&
                            <ul className='navigation-links'>
                                <li className={this.state.route === "/Pricing" ? "active-link" : ""}>
                                    <Link href='/pricing'>
                                        <a>Pricing</a>
                                    </Link>
                                </li>
                            </ul>
                        }
                        {this.props.isUserExist === undefined && (
                            <ul className='flex-row justify-content-around py-3'>
                                {
                                    NavigationStatus?.SIGN_IN &&
                                    <li className='dropdown'>
                                        <button type="button" id="signin-dropdown-mob" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            className='btn btn-outline-primary px-4'>
                                            Sign In
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="signin-dropdown-mob">
                                            <a className="dropdown-item p-2" href={process.env.NEXT_PUBLIC_FRONT_URI + "login" + query}>Sign In - Full Course</a>
                                            <a className="dropdown-item p-2" href={process.env.NEXT_PUBLIC_TESTPREP_URI}>Sign In - Mock Tests</a>
                                        </div>
                                    </li>
                                }
                                {
                                    NavigationStatus?.SIGN_UP &&
                                    <li>
                                        <button className='btn btn-primary px-4'>
                                            <a href={process.env.NEXT_PUBLIC_FRONT_URI + "signup" + query}>
                                                Sign Up
                                            </a>
                                        </button>
                                    </li>
                                }
                            </ul>
                        )}
                    </div>
                </nav>
            </div>
        );
    }
}

const propTypes = {
    isUserExist: PropTypes.string,
};

Navigation.propTypes = propTypes;

export default withRouter(Navigation);
