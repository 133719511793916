import React, { Component } from "react";
import Head from "next/head";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
export const config = { amp: true };
import SlackWebHook from "../services/Slack";

class Layout extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.queryAppend();
    }, 100);
    try {
      if(process.env.NEXT_PUBLIC_ENV === 'production' && process.env.NEXT_PUBLIC_SOURCE === 'COLLEGIFY-NEW'){
          document.getElementById('pageurl').value = window.location.href;
      }
    } catch (e) {
      console.log(e);
    }
  }

  componentDidCatch(error, info) {
    try {
      SlackWebHook.sendSlackMessage(
        `Layout-${error.message}-${JSON.stringify(info)}-${JSON.stringify(
          location
        )}`,
        process.env.NEXT_PUBLIC_SLACK_USERNAME,
        ":scream:",
        process.env.NEXT_PUBLIC_SLACK_BUG_CHANNEL
      );
    } catch (e) {
      console.log(e);
    }
  }

  queryAppend = () => {
    if (sessionStorage.getItem("gclid") || sessionStorage.getItem("fbclid") || sessionStorage.getItem("referral")) {
      let query = location.search.replace("?", "").replace("&", "=").split("=");
      if (!query.includes("gclid") && !query.includes("fbclid") && !query.includes("referral")) {
        let search = location.search === "" ? "?" : "";
        if (sessionStorage.getItem("gclid"))
          search +=
            search === "?"
              ? sessionStorage.getItem("gclid") &&
                "gclid=" + sessionStorage.getItem("gclid")
              : sessionStorage.getItem("gclid") &&
                "&gclid=" + sessionStorage.getItem("gclid");
        if (sessionStorage.getItem("fbclid"))
          search +=
            search === "?"
              ? sessionStorage.getItem("fbclid") &&
                "fbclid=" + sessionStorage.getItem("fbclid")
              : sessionStorage.getItem("fbclid") &&
                "&fbclid=" + sessionStorage.getItem("fbclid");
        if (sessionStorage.getItem("referral"))
          search +=
            search === "?"
              ? sessionStorage.getItem("referral") &&
                "referral=" + sessionStorage.getItem("referral")
              : sessionStorage.getItem("referral") &&
                "&referral=" + sessionStorage.getItem("referral");
        const href = `${location.href}${search}`;
        if (sessionStorage.getItem("path") !== location.pathname) {
          window.history.pushState(null, "", href);
          sessionStorage.setItem("path", location.pathname);
        }
      }
    } else {
      let search = location.search
        .replace("?", "")
        .replace("&", "=")
        .split("=");
      if (search.length > 0) {
        if (search.includes("gclid")) {
          const gci = search.findIndex((el) => el === "gclid");
          sessionStorage.setItem("gclid", search[gci + 1]);
        }
        if (search.includes("fbclid")) {
          const fbi = search.findIndex((el) => el === "fbclid");
          sessionStorage.setItem("fbclid", search[fbi + 1]);
        }
        if (search.includes("referral")) {
          const rei = search.findIndex((el) => el === "referral");
          sessionStorage.setItem("referral", search[rei + 1]);
        }
      }
    }
  };

  render() {
    return (
      <div className="layout">
        <Head>
          <title>
            {process.env.NEXT_PUBLIC_APP_NAME} | New Digital SAT and ACT Online Test Preparation and E-Learning
          </title>
          {/* <script src="https://checkout.razorpay.com/v1/checkout.js" defer /> */}
          {
              process.env.NEXT_PUBLIC_ENV === 'production' && process.env.NEXT_PUBLIC_SOURCE === 'COLLEGIFY-NEW' &&
              <>
                  <script src="https://www.googletagmanager.com/gtag/js?id=AW-975912079"/>
                  <script src="/js/SATgads.js" />
              </>
          }
        </Head>
        <Navigation isUserExist={this.props.isUserExist} />
        <main>{this.props.children}</main>
        <Footer />
      </div>
    );
  }
}

export default Layout;
